import { Button, Typography } from "@mui/material";
import { GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

import { tagListColumnSchema } from "./column-schema";

import { getAxiosError } from "@/utils/get-axios-error";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import DashboardHeader from "@/components/app/DashboardHeader";
import DashboardContent from "@/components/app/DashboardContent";
import { ITableParams } from "@/types/table";
import Table from "@/components/kit/Table";
import { modalActions } from "@/context/modals";
import { MODAL_TYPES } from "@/types/modals";
import { tagService } from "@/api/services/tag";
import { ITagListResponse } from "@/api/types/tag";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import SortAndFilters from "@/components/app/SortAndFilters";
import { filterListNames } from "@/constants/filter-list-names";
import { IGetListBody } from "@/api/types/base-data";
import { ILazyParams } from "@/types/filters";
import { DEFAULT_PAGE_SIZE } from "@/constants/paginations";

const TagList: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState<IGetListBody>();
    const [tags, setTags] = useState<any>();
    const [lazyParams, setLazyParams] = useState<ILazyParams>({
        filter: {},
        first: 0,
        page: 0,
        rows: DEFAULT_PAGE_SIZE,
        sort: {},
    });
    const createAccess = useCheckPermissionAccess("tag", "Create");
    const editAccess = useCheckPermissionAccess("tag", "Update");
    const deleteAccess = useCheckPermissionAccess("tag", "Delete");

    const fetchTags = useCallback(
        async (queryParams?: ITableParams) => {
            try {
                setLoading(true);
                const body = {
                    ...filters,
                    skip: lazyParams.page * DEFAULT_PAGE_SIZE,
                    take: lazyParams.rows,
                    ...queryParams,
                };

                if (lazyParams.sort && JSON.stringify(lazyParams.sort) !== "{}") {
                    body.sort = lazyParams.sort;
                }
                const result = await tagService.list(body);

                setTags(result);
                // eslint-disable-next-line unused-imports/no-unused-vars, no-unused-vars
            } catch (err) {
                enqueueSnackbar("Failed to load tags", { variant: "error" });
            } finally {
                setLoading(false);
            }
        },
        [filters, lazyParams]
    );

    useEffect(() => {
        fetchTags();
    }, [fetchTags, filters, lazyParams]);

    const onSortAndFiltersChanged = (data: IGetListBody) => {
        setFilters(data);
        setLazyParams({
            ...lazyParams,
            page: 0,
        });
    };

    const suffixCols = (
        tableData: ITagListResponse[],
        setTableData: Dispatch<SetStateAction<ITagListResponse[]>>
    ): GridColDef[] => {
        const handleDelete = (id: number) => {
            const handleAction = async () => {
                try {
                    setLoading(true);
                    await tagService.delete(id);
                    enqueueSnackbar(`Tag deleted successfully`, { variant: "success" });
                    setTableData((prev) => (prev ? prev.filter((item) => item.id !== id) : prev));
                } catch (err) {
                    const error = getAxiosError(err);
                    const message = error?.meta.message || "Server Error";

                    enqueueSnackbar(message, { variant: "error" });
                } finally {
                    setLoading(false);
                }
            };

            modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
                header: "Delete Confirmation",
                text: "Are you sure to delete this tag?",
                handleAction,
            });
        };

        return [
            {
                field: "actions",
                type: "actions",
                headerName: "Actions",
                filterable: false,
                hideable: false,
                resizable: false,
                flex: 1,
                align: "center",
                sortable: false,
                getActions: (params: GridRowParams<ITagListResponse>) => {
                    return [
                        ...(editAccess
                            ? [
                                  <Link
                                      key={params.row.id}
                                      to={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.TAGS.UPDATE.EDIT_BY_ID(params.row.id).ABSOLUTE}
                                  >
                                      <GridActionsCellItem color="inherit" icon={<EditIcon color="action" />} label="Edit" />
                                  </Link>,
                              ]
                            : []),
                        ...(deleteAccess
                            ? [
                                  <GridActionsCellItem
                                      key={params.row.id}
                                      color="inherit"
                                      icon={<DeleteIcon color="action" />}
                                      label="Delete"
                                      onClick={() => handleDelete(params.row.id)}
                                  />,
                              ]
                            : []),
                    ];
                },
            },
        ];
    };

    return (
        <>
            <DashboardHeader
                title="Tags"
                titleSuffix={
                    createAccess ? (
                        <Button
                            color="primary"
                            component={Link}
                            to={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.TAGS.ADD_NEW.ROOT.ABSOLUTE}
                            variant="contained"
                        >
                            Add New Tag
                        </Button>
                    ) : null
                }
            >
                <Typography component="p">List of all tags in the table.</Typography>
                <Typography component="p">
                    Tags are the labels for sections that users can see on the platform, such as popular or clustered
                    content.
                </Typography>
                <Typography component="p">You can edit, delete, and add new tags from this table.</Typography>
            </DashboardHeader>
            <SortAndFilters listName={filterListNames.TAGS} onChange={onSortAndFiltersChanged} />
            <DashboardContent>
                <Table
                    columnsSchema={tagListColumnSchema}
                    lazyParams={lazyParams}
                    loading={loading}
                    service={tags || []}
                    setLazyParams={setLazyParams}
                    showSearch={false}
                    suffixCols={suffixCols}
                />
            </DashboardContent>
        </>
    );
};

export default TagList;
