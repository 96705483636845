import { Autocomplete, Box, FormControl, FormHelperText, Paper, PaperProps, TextField } from "@mui/material";
import { ControllerRenderProps, FieldError, FieldValues } from "react-hook-form";

import { TextFieldSchema } from "@/types/form-generator-schema-type";
import { useGetCountries } from "@/api/hooks/useCountries";

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    placeholder: string;
    className?: string;
    errorMessage: FieldError;
    props?: TextFieldSchema["props"];
}

type valueType = {
    label: string,
    dialCode: string,
    Iso: string,
    flagIso: string,
    key: number,
};

const CustomPaper = (props: PaperProps) => {
    return <Paper elevation={8} {...props} sx={{ minWidth: "250px" }} />;
};

export default function PhoneCountryCode({ className, field, errorMessage }: IProps) {
    const { countries } = useGetCountries();

    if (countries.length === 0) return null;

    const data = countries.map((country) => ({
        key: country.id,
        label: country.title,
        dialCode: country.dialCode,
        Iso: country.currencyIso,
        flagIso: country.code2Char,
    }));

    const valueIndex = data.findIndex((item: valueType) => item.dialCode === field.value);

    const value = valueIndex >= 0 ? data[valueIndex] : ({ dialCode: "" } as valueType);

    return (
        <FormControl fullWidth className={className} margin="normal" variant="outlined">
            <Autocomplete
                disablePortal
                selectOnFocus
                PaperComponent={CustomPaper}
                disableClearable={value !== null}
                getOptionLabel={(option) => option.dialCode}
                isOptionEqualToValue={(option, value) => String(option.dialCode) === String(value)}
                options={data}
                renderInput={(params) => <TextField {...params} label="Country Code" />}
                renderOption={(renderProps, option) => {
                    return (
                        <Box {...renderProps} key={option.key} component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }}>
                            <img
                                alt=""
                                loading="lazy"
                                src={`https://flagcdn.com/w20/${option.flagIso.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.flagIso.toLowerCase()}.png 2x`}
                                width="20"
                            />
                            {option.label} (+{option.dialCode})
                        </Box>
                    );
                }}
                value={value}
                onChange={(_, value) => field.onChange(value?.dialCode)}
            />
            {errorMessage?.message ? <FormHelperText error>{errorMessage?.message}</FormHelperText> : null}
        </FormControl>
    );
}
