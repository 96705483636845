import { useMemo, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { DeleteOutline, Edit } from "@mui/icons-material";
import { Chip, IconButton, Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { getComparator } from "../../helper";

import headCells from "./tableCells";
import TableWrapperStyle from "./style";
import { Data, Order } from "./types";

import EnhancedTableHead from "@/components/app/EnhancedTableHeader";
import TableSkeleton from "@/components/app/TableSkeleton";
import Loading from "@/components/app/Loading/tableLoading";
import ConfirmationModal from "@/components/app/modalVariants/confirmation";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { useDeleteUI, useUpdateUI } from "@/api/hooks/useHomeUI";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";

interface IHomePageUITableProps {
    data: Data[];
    totalItems: number;
    page: number;
    rowsPerPage: number;
    isLoading: boolean;
    isFetching: boolean;
    // eslint-disable-next-line no-unused-vars
    onChange: (data: { page?: number, rowsPerPage?: number }) => void;
}

export default function HomePageUITable({
    data: rows,
    totalItems,
    rowsPerPage,
    page,
    isLoading,
    isFetching,
    onChange,
}: IHomePageUITableProps) {
    const navigate = useNavigate();
    const [order, setOrder] = useState<Order>("desc");
    const [orderBy, setOrderBy] = useState<keyof Data>("updatedAt");
    const [confirmModalId, setConfirmModal] = useState<number | null>(null);

    const editAccess = useCheckPermissionAccess("home_ui", "Update");
    const { mutate: deleteUI, isPending } = useDeleteUI();
    const { mutate: updateUI, isPending: updatePending } = useUpdateUI();

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === "asc";

        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        onChange({ page: newPage, rowsPerPage });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
    };

    const handleActivation = (id: number, active: boolean) => updateUI({ id: Number(id), body: { isActive: !active } });
    const handleEdit = (id: number) => navigate(ROUTE_CONSTANTS.APP_UI_MANAGEMENT.HOME.UPDATE.EDIT_BY_ID(id).ABSOLUTE);
    const handleClose = () => setConfirmModal(null);
    const handleDelete = () => {
        confirmModalId &&
            deleteUI(confirmModalId, {
                onSuccess: () => {
                    setConfirmModal(null);
                },
            });
    };

    const visibleRows = useMemo(() => [...rows].sort(getComparator(order, orderBy)), [order, orderBy, rows]);

    return (
        <TableWrapperStyle className="table-style-wrapper" sx={{ width: "100%", marginTop: "2em" }}>
            <TableContainer sx={{ maxHeight: "700px" }}>
                <Table
                    stickyHeader
                    aria-labelledby="tableTitle"
                    className="table"
                    component="div"
                    size="medium"
                    sx={{ padding: ".5em" }}
                >
                    <EnhancedTableHead<Data>
                        headCells={headCells}
                        order={order}
                        orderBy={orderBy}
                        rowCount={rows.length}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody component="div">
                        {isLoading && <TableSkeleton cells={headCells.map((item) => item.id)} />}
                        {!isLoading && !isFetching && visibleRows.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={headCells.length}>No Data To Show</TableCell>
                            </TableRow>
                        )}
                        {isFetching && <Loading />}
                        {visibleRows.map((row) => {
                            const { id, name, category, updatedAt, isActive } = row;

                            // ! ID of 1,2 is always default. contract between front-end and backend
                            const isDefault = id === 1 || id === 2;

                            return (
                                <TableRow key={id} className="table-row" component="div" role="checkbox" tabIndex={-1}>
                                    <TableCell align="center" className="table-cell" component="div">
                                        <span className="table-value">{id}</span>
                                    </TableCell>
                                    <TableCell align="center" className="table-cell" component="div">
                                        <span className="table-value">{name}</span>
                                    </TableCell>
                                    <TableCell align="center" className="table-cell" component="div">
                                        <span className="table-value" style={{ padding: "13px" }}>
                                            <Chip
                                                color={category.toLocaleLowerCase() === "education" ? "success" : "info"}
                                                label={category}
                                                size="small"
                                                variant="outlined"
                                            />
                                        </span>
                                    </TableCell>
                                    <TableCell align="center" className="table-cell" component="div">
                                        <span className="table-value fit">
                                            <Switch
                                                checked={isActive}
                                                color="success"
                                                disabled={!editAccess || isDefault}
                                                inputProps={{ "aria-label": "controlled" }}
                                                size="small"
                                                onChange={() => handleActivation(id, isActive)}
                                            />
                                        </span>
                                    </TableCell>
                                    <TableCell align="center" className="table-cell" component="div">
                                        <span className="table-value">{updatedAt}</span>
                                    </TableCell>
                                    <TableCell align="right" className="table-cell" component="div">
                                        <span className="table-value" style={{ padding: "5px", justifyContent: "flex-end" }}>
                                            <IconButton onClick={() => handleEdit(id)}>
                                                <Edit />
                                            </IconButton>
                                            <IconButton onClick={() => setConfirmModal(id)}>
                                                <DeleteOutline />
                                            </IconButton>
                                        </span>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={totalItems}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <ConfirmationModal
                description="Are you sure to delete this Item ?"
                handleClose={handleClose}
                loading={isPending || updatePending}
                open={Boolean(confirmModalId)}
                onConfirm={handleDelete}
            />
        </TableWrapperStyle>
    );
}
