import { Button, Stack } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import dayjs from "dayjs";

import { VideoHeaderStyled, VideoShowStyled } from "./styled";

import { videoService } from "@/api/services/video";
import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import InitLoading from "@/components/app/Loading/InitLoading";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { IVideoShowResponse } from "@/api/types/video";
import { IParams } from "@/types/params";
import { getAxiosError } from "@/utils/get-axios-error";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";

const VideoShow = () => {
    const { id } = useParams<IParams>();
    const hasShowId = Boolean(id);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<IVideoShowResponse>();

    const listAccess = useCheckPermissionAccess("video", "List");
    const editAccess = useCheckPermissionAccess("video", "Update");

    const getEditData = async () => {
        try {
            setLoading(true);
            const response = await videoService.show(Number(id));

            setData(response.data.data);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";

            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        hasShowId && getEditData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasShowId]);

    if (loading) return <InitLoading />;

    if (!data) return <h5>Oops! Something Went Wrong!</h5>;

    return (
        <>
            <DashboardHeader
                title={`Show Video`}
                titleSuffix={
                    <Stack direction={"row"} spacing={2}>
                        {editAccess ? (
                            <Button
                                component={Link}
                                to={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.VIDEOS.UPDATE.EDIT_BY_ID(data.id).ABSOLUTE}
                                variant="contained"
                            >
                                Edit Video
                            </Button>
                        ) : null}
                        {listAccess ? (
                            <Button component={Link} to={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.VIDEOS.ROOT.ABSOLUTE}>
                                Back To Video List
                            </Button>
                        ) : null}
                    </Stack>
                }
            />
            <DashboardContent>
                <VideoHeaderStyled>
                    <video controls poster={data.thumbnail?.url} preload="true" src={data.playableUrl}>
                        <source src={data.playableUrl} type="video/mp4" />
                        <source src={data.assets.mp4} type="video/mp4" />
                        <source src={data.assets.hls} type="video/m3u8" />
                        <track default kind="captions" src="SUBTITLE_PATH" srcLang="en" />
                        Your browser does not support the video.
                    </video>
                    <h1>{data.title}</h1>
                    <p>{data.description}</p>
                </VideoHeaderStyled>
                <VideoShowStyled>
                    <div>
                        <span className="label">Created At</span>
                        <span>{dayjs(data.createdAt).format("YYYY-MM-DD HH:mm")}</span>
                    </div>
                    <div>
                        <span className="label">Published At</span>
                        <span>{dayjs(data.publishedAt).format("YYYY-MM-DD HH:mm")}</span>
                    </div>
                    <div>
                        <span className="label">Updated At</span>
                        <span>{dayjs(data.updatedAt).format("YYYY-MM-DD HH:mm")}</span>
                    </div>
                </VideoShowStyled>
            </DashboardContent>
        </>
    );
};

export default VideoShow;
