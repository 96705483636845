import { Button, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import UserTable from "./table";
import { createData } from "./helpers";
import PageStyle from "./style";
import UserFilters, { defaultValues } from "./filters";

import DashboardContent from "@/components/app/DashboardContent";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { useUserList } from "@/api/hooks/useUsers";
import { IUserFilterParams } from "@/api/types/user";
import DashboardHeader from "@/components/app/DashboardHeader";

const UserList: React.FC = () => {
    const createAccess = useCheckPermissionAccess("user", "Create");

    const [params, setParams] = useState<IUserFilterParams>({
        search: "",
        skip: 0,
        take: 5,
        filter: {
            role: null,
            active: null,
            emailVerified: null,
        },
    });

    const { data: users, isFetching, isLoading } = useUserList(params);

    const totalItems = users?.data.pagination.totalItems;

    const handleChange = useCallback(({ page, rowsPerPage }: { page?: number, rowsPerPage?: number }) => {
        setParams((prev) => {
            return {
                ...prev,
                skip: Number(page) * Number(rowsPerPage),
                take: rowsPerPage || 10,
            };
        });
    }, []);

    const handleFilterOnApply = useCallback((filterData: typeof defaultValues) => {
        const { search, role, active, emailVerified } = filterData;

        setParams((prev) => {
            return {
                ...prev,
                search: String(search) || "",
                filter: {
                    role,
                    active,
                    emailVerified,
                },
            };
        });
    }, []);

    const genreData = useMemo(() => (users ? users.data.items.map((item) => createData(item)) : []), [users]);

    return (
        <PageStyle>
            <DashboardHeader
                title="Users"
                titleSuffix={
                    createAccess && (
                        <Button
                            color="primary"
                            component={Link}
                            to={ROUTE_CONSTANTS.USER_MANAGEMENT.USERS.ADD_NEW.ROOT.ABSOLUTE}
                            variant="contained"
                        >
                            Add New User
                        </Button>
                    )
                }
            >
                <Typography component="p">This table shows admin panel users.</Typography>
                <Typography component="p">Based on your access level, you can view and manage their details.</Typography>
            </DashboardHeader>

            <DashboardContent>
                <UserFilters onApply={handleFilterOnApply} />
                <UserTable
                    data={genreData}
                    isFetching={isFetching}
                    isLoading={isLoading}
                    page={Number(params.skip) / Number(params.take)}
                    rowsPerPage={Number(params.take)}
                    totalItems={totalItems || 0}
                    onChange={handleChange}
                />
            </DashboardContent>
        </PageStyle>
    );
};

export default UserList;
