import { Button, Chip, Stack } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import dayjs from "dayjs";

import { CustomerShowStyled } from "./styled";

import { customerService } from "@/api/services/customer";
import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import InitLoading from "@/components/app/Loading/InitLoading";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { ICustomerListResponse } from "@/api/types/customer";
import { IParams } from "@/types/params";
import { getAxiosError } from "@/utils/get-axios-error";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";

const CustomerShow = () => {
    const { id } = useParams<IParams>();
    const hasShowId = Boolean(id);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<ICustomerListResponse>();
    const listAccess = useCheckPermissionAccess("user", "List");
    const editAccess = useCheckPermissionAccess("user", "Update");

    const getEditData = async () => {
        try {
            setLoading(true);
            const response = await customerService.show(Number(id));

            setData(response.data.data);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";

            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        hasShowId && getEditData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasShowId]);

    if (loading) return <InitLoading />;

    if (!data) return <h5>Oops! Something Went Wrong!</h5>;

    return (
        <>
            <DashboardHeader
                title={`${data.firstName} ${data.lastName}`}
                titleSuffix={
                    <Stack direction={"row"} spacing={2}>
                        {editAccess ? (
                            <Button
                                component={Link}
                                to={ROUTE_CONSTANTS.USER_MANAGEMENT.CUSTOMERS.UPDATE.EDIT_BY_ID(data.id).ABSOLUTE}
                                variant="contained"
                            >
                                Edit Customer
                            </Button>
                        ) : null}
                        {listAccess ? (
                            <Button component={Link} to={ROUTE_CONSTANTS.USER_MANAGEMENT.CUSTOMERS.ROOT.ABSOLUTE}>
                                Back To Customer List
                            </Button>
                        ) : null}
                    </Stack>
                }
            />
            <DashboardContent>
                <CustomerShowStyled>
                    <div>
                        <span className="label">First Name</span>
                        <span>{data.firstName}</span>
                    </div>
                    <div>
                        <span className="label">Last Name</span>
                        <span>{data.lastName}</span>
                    </div>
                    <div>
                        <span className="label">Email</span>
                        <span>
                            <Stack alignItems="center" direction="row" spacing={1}>
                                <span>{data.email}</span>
                                {data.emailVerified ? <Chip color="success" label="Verified" variant="outlined" /> : null}
                            </Stack>
                        </span>
                    </div>
                    <div>
                        <span className="label">Phone Number</span>
                        <span>{data.phoneNumber}</span>
                    </div>
                    <div>
                        <span className="label">State</span>
                        <span>
                            {data.state === "Enable" ? (
                                <Chip color="success" label="Enable" variant="outlined" />
                            ) : (
                                <Chip color="error" label="Blocked" variant="outlined" />
                            )}
                        </span>
                    </div>
                    <div>
                        <span className="label">Created At</span>
                        <span>{dayjs(data.createdAt).format("YYYY-MM-DD HH:mm")}</span>
                    </div>
                    <div>
                        <span className="label">Updated At</span>
                        <span>{dayjs(data.updatedAt).format("YYYY-MM-DD HH:mm")}</span>
                    </div>
                </CustomerShowStyled>
            </DashboardContent>
        </>
    );
};

export default CustomerShow;
