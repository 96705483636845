import { Typography } from "@mui/material";

import { customerListColumnSchema } from "./column-schema";

import { customerService } from "@/api/services/customer";
import Table from "@/components/kit/Table";
import { ITableParams } from "@/types/table";
import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";

const service = async (queryParams?: ITableParams) => customerService.list(queryParams);

const CustomerList: React.FC = () => {
    return (
        <>
            <DashboardHeader title="Customers">
                <Typography component="p">Search between your customers and find their data and history.</Typography>
                <Typography component="p">Their invoice of their subscription will send to their Email.</Typography>
            </DashboardHeader>
            <DashboardContent>
                <Table columnsSchema={customerListColumnSchema} service={service} />
            </DashboardContent>
        </>
    );
};

export default CustomerList;
