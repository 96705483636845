import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { enqueueSnackbar } from "notistack";

import UserForm from "./form";
import { IUserCreateFormType } from "./form-type";

import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import { getAxiosError } from "@/utils/get-axios-error";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { IParams } from "@/types/params";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { useCreateUser, useUpdateUser } from "@/api/hooks/useUsers";

const UserFormWrapper: React.FC = () => {
    const { id } = useParams<IParams>();
    const isEditingRule = Boolean(id);
    const navigate = useNavigate();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const listAccess = useCheckPermissionAccess("user", "List");

    const { mutate: UpdateUser } = useUpdateUser();
    const { mutate: CreateUser } = useCreateUser();

    const handleSubmit = async (_val: IUserCreateFormType) => {
        const { phone, countryCode, ...rest } = _val;

        const phoneNumber = `+${countryCode}-${phone}`;

        const payload = {
            ...rest,
            phoneNumber,
        };

        try {
            setSubmitLoading(true);
            if (isEditingRule) {
                // eslint-disable-next-line no-unused-vars, unused-imports/no-unused-vars
                const { email, ...rest } = payload;

                UpdateUser({ id: Number(id), body: rest });
            } else {
                CreateUser({ body: payload });
            }
            enqueueSnackbar(`${isEditingRule ? "User updated" : "New user created"} successfully`, { variant: "success" });
            navigate(ROUTE_CONSTANTS.USER_MANAGEMENT.USERS.ROOT.ABSOLUTE);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";

            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setSubmitLoading(false);
        }
    };

    return (
        <>
            <DashboardHeader
                title={`${isEditingRule ? "Update" : "Create New"} User`}
                {...(listAccess
                    ? {
                          titleSuffix: (
                              <Button component={Link} to={ROUTE_CONSTANTS.USER_MANAGEMENT.USERS.ROOT.ABSOLUTE}>
                                  Back To User List
                              </Button>
                          ),
                      }
                    : undefined)}
            />
            <DashboardContent>
                <UserForm loading={submitLoading} onSubmit={handleSubmit} />
            </DashboardContent>
        </>
    );
};

export default UserFormWrapper;
