import { GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Chip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import { ICustomerListResponse } from "@/api/types/customer";
import ROUTE_CONSTANTS from "@/constants/route-constants";

export const customerListColumnSchema: GridColDef[] = [
    {
        field: "id",
        headerName: "ID",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
    },
    {
        field: "firstName",
        headerName: "First Name",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
    },
    {
        field: "lastName",
        headerName: "Last Name",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
    },
    {
        field: "email",
        headerName: "Email",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
    },
    {
        field: "phoneNumber",
        headerName: "Phone Number",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
    },
    {
        field: "state",
        headerName: "Status",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
        renderCell(params: GridRenderCellParams<ICustomerListResponse>) {
            return (
                <Chip
                    color={params.row.state === "Disable" ? "error" : "success"}
                    icon={params.row.state === "Disable" ? <CancelIcon /> : <CheckCircleIcon />}
                    label={params.row.state === "Disable" ? "Blocked" : "Active"}
                    size="small"
                    variant="outlined"
                />
            );
        },
    },
    {
        field: "updatedAt",
        headerName: "Updated At",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
        renderCell(params: GridRenderCellParams<ICustomerListResponse>) {
            return dayjs(params.row.updatedAt).format("YYYY-MM-DD HH:mm");
        },
    },
    {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        align: "center",
        sortable: false,
        getActions: (params: GridRowParams<ICustomerListResponse>) => {
            return [
                <Link
                    key={params.row.id}
                    to={ROUTE_CONSTANTS.USER_MANAGEMENT.CUSTOMERS.SHOW.SHOW_BY_ID(params.row.id).ABSOLUTE}
                >
                    <GridActionsCellItem color="inherit" icon={<ArrowForwardIcon color="action" />} label="SHOW" />
                </Link>,
            ];
        },
    },
];
