import * as yup from "yup";

import { arabicCharactersRegex } from "@/constants/regexes";

export default yup.object().shape({
    sharing_state: yup.number(),
    en_title: yup
        .string()
        .min(2, "English title must be at most 2 characters")
        .max(80, "English title must be at least 80 characters")
        .required("English title is required"),
    ar_title: yup
        .string()
        .min(2, "Arabic title must be at most 2 characters")
        .max(80, "Arabic title must be at least 80 characters")
        .matches(arabicCharactersRegex, "Arabic title must only accept Arabic characters"),
    en_description: yup.string().max(600, "English description must be at least 600 characters"),
    ar_description: yup
        .string()
        .max(600, "Arabic description must be at least 600 characters")
        .matches(arabicCharactersRegex, "Arabic title must only accept Arabic characters"),
    season: yup
        .number()
        .positive()
        .when("autoId", ([autoId], schema) => {
            return autoId ? schema : schema.required("This field is required");
        }),
    autoId: yup.boolean().required("This field is required"),
});
