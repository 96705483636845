import { number, object, string } from "yup";

import { arabicCharactersRegex } from "@/constants/regexes";

export const createTagValidationSchema = object().shape({
    en_title: string()
        .required("English title is required")
        .min(3, "English title must be at most 3 characters")
        .max(80, "English title must be at least 80 characters"),
    ar_title: string()
        .required("Arabic title is required")
        .min(3, "Arabic title must be at most 3 characters")
        .max(80, "Arabic title must be at least 80 characters")
        .matches(arabicCharactersRegex, "Arabic title must only accept Arabic characters"),
    slug: string()
        .min(3, "Slug must be at most 3 characters")
        .max(255, "Slug must be at least 255 characters")
        .required("Slug is required"),
    category: number().required("Category is required").integer(),
});
