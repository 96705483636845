import { Box, Button, Chip, IconButton } from "@mui/material";
// import { Check } from "@mui/icons-material";
import { BorderColor, DeleteOutlineOutlined } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import {
    PlanCardBodyStyled,
    // PlanCardCheckIconStyled,
    PlanCardContentStyled,
    PlanCardHeaderStyled,
    PlanCardStyled,
    PlanCardWrapperStyled,
} from "./style";

import starBigBlueImg from "@/assets/svg/subscription/star-big-blue.svg";
import starSmallBlueImg from "@/assets/svg/subscription/star-small-blue.svg";
import starBigWhiteImg from "@/assets/svg/subscription/star-big-white.svg";
import starSmallWhiteImg from "@/assets/svg/subscription/star-small-white.svg";
import verifiedCheckImg from "@/assets/svg/subscription/verified-check.svg";
import { useDeletePlan } from "@/api/hooks/usePlans";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import ROUTE_CONSTANTS from "@/constants/route-constants";

interface Props {
    id: number;
    active?: boolean;
    title: string;
    subTitle: string;
    isRecommended?: boolean;
    hasSubscribeButton?: boolean;
    checkIcon?: boolean;
    regularPrice?: number;
    currency: string;
    salePrice?: number;
    features: string[];
}

const PlanCard: React.FC<Props> = ({
    title,
    subTitle,
    features,
    regularPrice,
    salePrice,
    currency,
    hasSubscribeButton = true,
    isRecommended = false,
    active = false,
    checkIcon = false,
    id,
}) => {
    const starBig = active ? starBigBlueImg : starBigWhiteImg;
    const starSmall = active ? starSmallBlueImg : starSmallWhiteImg;

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const deleteAccess = useCheckPermissionAccess("plan", "Delete");

    const { mutate: deletePlan } = useDeletePlan();

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();
        navigate(ROUTE_CONSTANTS.SETTINGS.PLANS.EDIT.ROOT(id).ABSOLUTE);
    };

    const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();
        if (deleteAccess) {
            id && deletePlan({ id });
        } else {
            enqueueSnackbar("You don't have the correct rights to Delete a plan.", { variant: "warning" });
        }
    };

    return (
        <PlanCardWrapperStyled className="wrapper">
            <PlanCardStyled className={`${active ? "active" : ""} ${checkIcon ? "clickable" : ""}`}>
                <div className="shadow" />
                <PlanCardContentStyled className="content">
                    <Box className="content-header">
                        <Chip
                            color={active ? "warning" : "info"}
                            label={active ? "Active" : "Not Active"}
                            size="small"
                            variant="outlined"
                        />
                        <Box className="actions">
                            <IconButton onClick={handleDelete}>
                                <DeleteOutlineOutlined />
                            </IconButton>
                            <IconButton onClick={handleClick}>
                                <BorderColor />
                            </IconButton>
                        </Box>
                    </Box>
                    <img alt="starBigImg" className="starBigImg" height={37} src={starBig} width={37} />
                    <img alt="starSmallImg" className="starSmallImg" height={23} src={starSmall} width={23} />
                    <PlanCardHeaderStyled className={active ? "active" : ""}>
                        <small>
                            {title}

                            {isRecommended && <span>Recommended</span>}
                        </small>
                        <div>
                            <span>
                                {currency}{" "}
                                {salePrice ||
                                    (salePrice === 0 && (
                                        <small>
                                            {currency} {regularPrice}
                                        </small>
                                    ))}
                            </span>
                            <p>{subTitle}</p>
                        </div>
                        {hasSubscribeButton && (
                            <Button color="primary" variant="contained">
                                Subscribe
                            </Button>
                        )}
                    </PlanCardHeaderStyled>
                    <PlanCardBodyStyled className="features">
                        <span className="title">Features</span>
                        <ul>
                            {features.map((item, index) => (
                                <li key={index}>
                                    <img alt="verifiedCheckImg" height={24} src={verifiedCheckImg} width={24} />
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </PlanCardBodyStyled>
                </PlanCardContentStyled>
            </PlanCardStyled>
        </PlanCardWrapperStyled>
    );
};

export default PlanCard;
