// import { withProtectedRoutes } from "./ProtectedRoute";
import withCheckPermissions from "@/layouts/GeneralLayoutWrapper/withCheckPermissions";
import CustomerList from "@/pages/UserManagement/Customer";
import CustomerForm from "@/pages/UserManagement/Customer/AddUpdate";
import CustomerShow from "@/pages/UserManagement/Customer/Show";
// import UserManagement from "@/pages/UserManagement";
import UserList from "@/pages/UserManagement/User";
import UserForm from "@/pages/UserManagement/User/AddUpdate";

// const UserManagementProtected = withProtectedRoutes(UserManagement, [PERMISSIONS.USER_LIST]);
export const ProtectedUserList = withCheckPermissions(UserList, "user", "List");
export const ProtectedUserFormUpdate = withCheckPermissions(UserForm, "user", "Update");
export const ProtectedUserFormCreate = withCheckPermissions(UserForm, "user", "Create");

export const ProtectedCustomerList = withCheckPermissions(CustomerList, "customer", "List");
export const ProtectedCustomerFormUpdate = withCheckPermissions(CustomerForm, "customer", "Update");
export const ProtectedCustomerFormDetails = withCheckPermissions(CustomerShow, "customer", "List");
