export const API_ROUTES = {
    BASE_URL: "/",
    AUTH: {
        LOGIN: "/auth/admin/login",
        CHANGE_PASSWORD: "/auth/admin/change-password",
        REFRESH: "/auth/admin/refresh",
        RESET_PASSWORD: "/auth/admin/reset-password",
        FORGET_PASSWORD: "/auth/admin/forget-password",
        PERMISSIONS: "/auth/admin/permissions",
    },
    USER: {
        LIST: "/users",
        PROFILE: "/users/my-account",
        CREATE: "/users/create",
        UPDATE: (id: number) => `/users/${id}`,
        SHOW: (id: number) => `/users/${id}`,
        DELETE: (id: number) => `/users/${id}`,
        ACTIVATION: (id: number) => `/users/activation/${id}`,
    },
    CUSTOMER: {
        LIST: "/customers",
        UPDATE: (id: number) => `/customers/${id}`,
        SHOW: (id: number) => `/customers/${id}`,
        BLOCK: (id: number) => `/customers/block/${id}`,
    },
    TAG: {
        LIST: "/tags",
        CREATE: "/tags/create",
        UPDATE: (id: number) => `/tags/${id}`,
        SHOW: (id: number) => `/tags/${id}`,
        DELETE: (id: number) => `/tags/${id}`,
    },
    GENRE: {
        LIST: "/genres",
        CREATE: "/genres/create",
        UPDATE: (id: number) => `/genres/${id}`,
        SHOW: (id: number) => `/genres/${id}`,
        DELETE: (id: number) => `/genres/${id}`,
        SLUG: (slug: string) => `/genres/slug/${slug}`,
    },
    CONTENT: {
        TYPE: "/content-types",
        AGE_RANGE: "/age-ranges",
        LIST: "/contents",
        CREATE: "/contents/create",
        UPDATE: (id: number) => `/contents/${id}`,
        SHOW: (id: number) => `/contents/${id}`,
        DELETE: (id: number) => `/contents/${id}`,
    },
    VIDEO: {
        LIST: "/videos",
        SHOW: (id: number) => `/videos/${id}`,
        CREATE: "/videos/create",
        UPDATE: (id: number) => `/videos/${id}`,
        DELETE: (id: number) => `/videos/${id}`,
        TOKEN: "/videos/upload-token",
        WEBHOOKS: "/videos/webhooks",
    },
    VIDEO_CAPTIONS: {
        LIST: (id: number) => `/videos/${id}/captions`,
        GET_BY_ID_AND_LANG: (id: number, lang: string) => `/videos/${id}/captions/${lang}`,
        DELETE: (id: number, lang: string) => `/videos/${id}/captions/${lang}`,
        CREATE: (id: number, lang: string) => `/videos/${id}/captions/${lang}`,
        DEFAULT: (id: number, lang: string) => `/videos/${id}/captions/${lang}/set-default`,
    },
    IMAGE: {
        LIST: "/medias",
        CREATE: "/medias/create",
        GET_BY_ID: (id: number) => `/medias/${id}`,
        DELETE: (id: number) => `/medias/${id}`,
    },
    AVATARS: {
        LIST: "/avatars",
        CREATE: "/avatars/add",
        DELETE: (id: number) => `/avatars/${id}`,
    },
    HOME: {
        LIST: "/home-page/uis",
        CREATE: "/home-page/uis/create",
        UPDATE: (id: number) => `/home-page/uis/${id}`,
        DELETE: (id: number) => `/home-page/uis/${id}`,
        SHOW: (id: number) => `/home-page/uis/${id}`,
    },
    STATIC: {
        LIST: "/static-pages",
        CREATE: "/static-pages/create",
        UPDATE: (id: number) => `/static-pages/${id}`,
        SHOW: (id: number) => `/static-pages/${id}`,
        DELETE: (id: number) => `/static-pages/${id}`,
    },
    BASE_DATA: {
        LIST_INFO: "/list-infos",
        SHOW: (listName: string) => `/list-infos/${listName}`,
    },
    PUBLISHER: {
        GET_BY_ID: (id: number) => `/publishers/${id}`,
        UPDATE: (id: number) => `/publishers/${id}`,
        DELETE: (id: number) => `/publishers/${id}`,
        ADD: `/publishers/create`,
        LIST: `/publishers`,
    },
    PLANS: {
        CHECKOUT: (id: number) => `/plans/${id}/checkout`,
        GET_BY_ID: (id: number) => `/plans/${id}`,
        UPDATE: (id: number) => `/plans/${id}`,
        DELETE: (id: number) => `/plans/${id}`,
        ADD: `/plans/create`,
        LIST: `/plans`,
    },
};
