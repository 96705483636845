import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Divider, Typography } from "@mui/material";
import { useEffect } from "react";
import { Controller, FieldError, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";

import Validation from "./validation";
import { ICreateEpisodeForm } from "./types";
import AddEpisodesStyle from "./style";

import { useContentById } from "@/api/hooks/useContents";
import FormSelectVideo from "@/components/kit/FormGenerator/FormSelectVideo";
import FormSelectImage from "@/components/kit/FormGenerator/FormSelectImage";
import FormTextarea from "@/components/kit/FormGenerator/FormTextarea";
import FormText from "@/components/kit/FormGenerator/FormText";
import FormTextRtl from "@/components/kit/FormGenerator/FormTextRtl";
import FormTextareaRtl from "@/components/kit/FormGenerator/FormTextareaRtl";
import FormCheckbox from "@/components/kit/FormGenerator/FormCheckbox";
import FormRadio from "@/components/kit/FormGenerator/FormRadio";
import SubtitleUploader from "@/components/app/subtitleUploader";

const defaultValues = {
    en_title: "",
    ar_title: "",
    en_description: "",
    ar_description: "",
    mainVideo: null,
    trailerVideo: 0,
    thumbnailLandscapeId: null,
    thumbnailPortraitId: null,
    autoId: false,
    episode: 1,
    sharing_state: 1,
};

interface IAddEpisode {
    loading: boolean;
    episodeId: number | null;
    // eslint-disable-next-line no-unused-vars
    onSubmit: (formData: ICreateEpisodeForm) => void;
}

export default function AddEpisodes({ episodeId, loading, onSubmit }: IAddEpisode) {
    const { data: EpisodeData } = useContentById(Number(episodeId));

    const content = EpisodeData?.data.data;

    const {
        control,
        reset,
        handleSubmit,
        watch,
        trigger,
        formState: { errors, isValid },
    } = useForm<ICreateEpisodeForm>({
        defaultValues,
        mode: "all",
        resolver: yupResolver(Validation),
    });

    const videoId = watch("mainVideo");

    // this code is very important - it's like refresh button for react-hook-form, make it update.
    useEffect(() => {
        if (isValid) void trigger();
    }, [trigger, isValid]);

    // update form data in Edit mode
    useEffect(() => {
        // make sure genre & tags & ageRange are fetched before
        if (content) {
            const {
                ml_description,
                mainVideo,
                thumbnailLandscape,
                thumbnailPortrait,
                ml_name,
                episode,
                status,
                trailerVideo,
            } = content;

            reset({
                episode: episode,
                mainVideo: mainVideo?.id,
                trailerVideo: trailerVideo?.id,
                thumbnailLandscapeId: thumbnailLandscape?.id,
                thumbnailPortraitId: thumbnailPortrait?.id,
                en_title: ml_name.en,
                ar_title: ml_name.ar,
                ar_description: ml_description.ar,
                en_description: ml_description.en,
                sharing_state: status === "Draft" ? 1 : 2,
            });
        }
    }, [content, reset]);

    useEffect(() => {
        if (!episodeId) reset(defaultValues);
    }, [reset, episodeId]);

    const autoId = watch("autoId");
    const required = Number(watch("sharing_state")) === 2;

    return (
        <AddEpisodesStyle className="add-episode-form-wrapper">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="section media">
                    <Typography sx={{ fontSize: "12px" }}>
                        If you want the episode numbers to be consecutive, you must enter the episode number yourself.
                    </Typography>
                    <Controller
                        key="episode"
                        control={control}
                        disabled={autoId}
                        name="episode"
                        render={({ field }) => (
                            <FormText
                                errorMessage={errors["episode"] as FieldError}
                                field={{ ...field }}
                                label="Enter Episode No"
                                placeholder="Enter Episode No"
                                props={{ type: "number" }}
                            />
                        )}
                    />
                    <Controller
                        key="autoId"
                        control={control}
                        name="autoId"
                        render={({ field }) => (
                            <FormCheckbox
                                className="auto-id field"
                                errorMessage={errors["autoId"] as FieldError}
                                field={{ ...field }}
                                label="Add episode number automatically (I do not care about the order of episodes)"
                                placeholder="Add episode number automatically (I do not care about the order of episodes)"
                            />
                        )}
                    />

                    <h3 className="title">Episode&rsquo;s Media </h3>

                    <div className="content">
                        <div className="col left-sec">
                            <div className="portrait-box">
                                <Controller
                                    key="thumbnailPortraitId"
                                    control={control}
                                    name="thumbnailPortraitId"
                                    render={({ field }) => (
                                        <FormSelectImage
                                            className="portrait-thumb"
                                            errorMessage={errors["thumbnailPortraitId"] as FieldError}
                                            field={{ ...field }}
                                            label="Thumbnail Portrait "
                                            placeholder="Image File"
                                            props={{ previewImage: content?.thumbnailPortrait?.url, required }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="col right-sec">
                            <div className="landscape-box">
                                <Controller
                                    key="thumbnailLandscapeId"
                                    control={control}
                                    name="thumbnailLandscapeId"
                                    render={({ field }) => (
                                        <FormSelectImage
                                            className="landscape-thumb"
                                            errorMessage={errors["thumbnailLandscapeId"] as FieldError}
                                            field={{ ...field }}
                                            label="Thumbnail Landscape "
                                            placeholder="Image File"
                                            props={{ previewImage: content?.thumbnailLandscape?.url, required }}
                                        />
                                    )}
                                />
                                <div className="video-section">
                                    <Controller
                                        key="mainVideo"
                                        control={control}
                                        name="mainVideo"
                                        render={({ field }) => (
                                            <FormSelectVideo
                                                className="select-video"
                                                errorMessage={errors["mainVideo"] as FieldError}
                                                field={{ ...field }}
                                                label="Select Video"
                                                placeholder="Video File"
                                                props={{ previewImage: content?.mainVideo?.thumbnail?.url, required }}
                                            />
                                        )}
                                    />
                                    <Controller
                                        key="trailerVideo"
                                        control={control}
                                        name="trailerVideo"
                                        render={({ field }) => (
                                            <FormSelectVideo
                                                className="select-trailer"
                                                errorMessage={errors["trailerVideo"] as FieldError}
                                                field={{ ...field }}
                                                label="Select Trailer Video"
                                                placeholder="Video File"
                                                props={{
                                                    previewImage: content?.trailerVideo?.thumbnail?.url,
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section subtitles">
                    <h3 className="title">Episode&rsquo;s Subtitles </h3>
                    <div className="content row">
                        {!!videoId ? (
                            <>
                                <SubtitleUploader id={videoId} label="Select English Subtitle" lang="en" />
                                <SubtitleUploader id={videoId} label="Select Arabic Subtitle" lang="ar" />
                            </>
                        ) : (
                            <Typography sx={{ margin: "10px 0" }}>
                                In order to upload any subtitle you need to select main video first
                            </Typography>
                        )}
                    </div>
                </div>
                <Divider />
                <div className="section">
                    <h3 className="title">Episode&rsquo;s Information </h3>
                    <div className="content grid">
                        <Controller
                            key="en_title"
                            control={control}
                            name="en_title"
                            render={({ field }) => (
                                <FormText
                                    className="en_title field"
                                    errorMessage={errors["en_title"] as FieldError}
                                    field={{ ...field }}
                                    label="English Content Name "
                                    placeholder="Enter English Text"
                                    props={{ required: true }}
                                />
                            )}
                        />
                        <Controller
                            key="ar_title"
                            control={control}
                            name="ar_title"
                            render={({ field }) => (
                                <FormTextRtl
                                    className="ar_title field"
                                    errorMessage={errors["ar_title"] as FieldError}
                                    field={{ ...field }}
                                    label="Arabic Content Name "
                                    placeholder="Enter Arabic Text"
                                    props={{ required: true }}
                                />
                            )}
                        />
                        <Controller
                            key="en_description"
                            control={control}
                            name="en_description"
                            render={({ field }) => (
                                <FormTextarea
                                    className="en_description field"
                                    errorMessage={errors["en_description"] as FieldError}
                                    field={{ ...field }}
                                    label="English Description"
                                    placeholder="Enter English Text"
                                    props={{ required }}
                                />
                            )}
                        />
                        <Controller
                            key="ar_description"
                            control={control}
                            name="ar_description"
                            render={({ field }) => (
                                <FormTextareaRtl
                                    className="ar_description field"
                                    errorMessage={errors["ar_description"] as FieldError}
                                    field={{ ...field }}
                                    label="Arabic Description"
                                    placeholder="Enter Arabic Text"
                                    props={{ required }}
                                />
                            )}
                        />
                        <Controller
                            key="sharing_state"
                            control={control}
                            name="sharing_state"
                            render={({ field }) => (
                                <FormRadio
                                    className="sharing_state"
                                    data={[
                                        { value: 1, label: "Draft" },
                                        { value: 2, label: "Publish" },
                                    ]}
                                    errorMessage={errors["sharing_state"] as FieldError}
                                    field={{ ...field }}
                                    label="Sharing State"
                                    placeholder="Select Option"
                                    props={{ required: true }}
                                />
                            )}
                        />
                    </div>
                </div>

                <Box className="actions" sx={{ display: "flex", justifyContent: "flex-start", marginTop: "10px" }}>
                    <LoadingButton disabled={loading} loading={loading} type="submit">
                        {episodeId ? "Update" : "Create"} Content
                    </LoadingButton>
                </Box>
            </form>
        </AddEpisodesStyle>
    );
}
